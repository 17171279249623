import React from 'react'

export default function ForthSection() {
  return (
    <div id='our-goal' className="forth-section">
      <img className='accent third-ball mobile-hide' alt='accent' src="images/ball-3.svg" />
      <img className='accent forth-dot mobile-hide' alt='accent' src="images/dot-accent-2.png" />
      <img className='accent fifth-ball' alt='accent' src="images/ball-6.svg" />
      <img className='accent sixth-ball' alt='accent' src="images/ball-5.svg" />
      <img className='accent sixth-dot' alt='accent' src="images/dot-accent-3.png" />
      <img className='accent second-wire' alt='accent' src="images/wire-accent.svg" />
      <div className="third-section-container" style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
        <div style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 60, display: 'inline-flex'}}>
          <div className='header-container' style={{alignSelf: 'stretch', height: 229.74, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'flex'}}>
            <h1 style={{alignSelf: 'stretch', textAlign: 'center'}}><span style={{color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Our </span><span style={{color: '#2CE2ED', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Goal</span></h1>
            <div className='our-goal-section' style={{position: 'relative'}}>
              <div className='container' style={{width: 739, height: 120, paddingLeft: 80, paddingRight: 80, paddingTop: 40, paddingBottom: 40, left: 23, top: 13, background: 'linear-gradient(180deg, #FF8CC3 0%, #D161E4 100%)', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                  <h2 style={{textAlign: 'center', color: 'white', fontSize: 32, fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Mass effort achieves greatness.</h2>
              </div>
              <div style={{width: 60, height: 38.74, left: -25, top: -10, position: 'absolute'}}>
                <img src="images/icon-out-goal.svg" alt="" srcSet="" />
              </div>
              <div style={{width: 60, height: 38.74, right: -25, bottom: -10, position: 'absolute', transform: 'rotate(180deg)'}}>
                <img src="images/icon-out-goal.svg" alt="" srcSet="" />
              </div>
            </div>
          </div>
          <div className="text-our-goal" style={{alignSelf: 'stretch', textAlign: 'center'}}><span style={{color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Squad3 envisions a Web3 world where everyone, from individuals to teams, </span><span style={{color: '#2CE2ED', fontSize: 14, fontFamily: 'Inter', fontWeight: '700', lineHeight: "40px", wordWrap: 'break-word'}}>can contribute and be fairly rewarded</span><span style={{color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>, not just a privileged few. <br/><br/>Our mission is to make Web3 participation accessible and incentivize active engagement through a </span><span style={{color: '#2CE2ED', fontSize: 14, fontFamily: 'Inter', fontWeight: '700', lineHeight: "40px", wordWrap: 'break-word'}}>transparent reward system</span><span style={{color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>, driving the healthy growth of Web3 communities.</span></div>
      </div>
      </div>
      <div className="third-section-container third-section" style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
        <div className='header-backer-section' style={{width: 1090, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
          <h1 style={{color: 'white', fontSize: 48, fontWeight: '700', wordWrap: 'break-word'}}>Backers</h1>
        </div>
        <div className="second-first-container second-row" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
          <div className="second-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-1.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-2.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-3.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-4.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-5.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-6.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-7.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-8.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-9.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-10.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-11.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-12.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-13.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-14.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-15.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-16.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-17.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-18.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-19.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-20.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-21.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-22.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-23.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='backer-image-container' style={{width: '100%', height: '100%', position: 'relative'}}>
              <img src="images/backer-24.svg" alt="Icon-1" srcSet="" />
            </div>
          </div>
        </div>
      </div>
      <h2 className="third-section-container forth-section" style={{textAlign: 'center', color: '#FFCE41', fontSize: 32, fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word', zIndex: 2}}>Squad Up Together, New experience to earn in Web3</h2>
      <div className="third-section-container footer" style={{width: '100%', height: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex'}}>
        <div style={{justifyContent: 'flex-start', alignItems: 'center', gap: 31, display: 'flex'}}>
            <div onClick={() => {
              window.open("https://x.com/BuildWithSquad3", '_blank').focus();
            }} style={{cursor: "pointer", justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
              <div style={{width: 18, height: 18, position: 'relative'}}>
                <img src="images/icon-x.svg" alt="X-logo" srcSet="" />
              </div>
              <div className='desktop' style={{color: 'white', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word'}}>BuildWithSquad3</div>
            </div>
            <div onClick={() => {
              window.open("https://t.me/BuildWithSquad3", '_blank').focus();
            }} style={{cursor: "pointer", justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
              <div style={{width: 18, height: 18, position: 'relative'}}>
                <img src="images/icon-telegram.svg" alt="telegram-logo" srcSet="" />
              </div>
              <div className='desktop' style={{color: 'white', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word'}}>BuildWithSquad3</div>
            </div>
            <div onClick={() => {
              window.open("https://medium.com/@BuildWithSquad3", '_blank').focus();
            }} style={{cursor: "pointer", justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'flex'}}>
              <div style={{width: 24, height: 24, position: 'relative'}}>
                <img src="images/icon-medium.svg" alt="medium-logo" srcSet="" />
              </div>
              <div className='desktop' style={{color: 'white', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word'}}>BuildWithSquad3</div>
            </div>
        </div>
        <div style={{color: 'white', fontSize: 14, fontFamily: 'Poppins', fontWeight: '400', lineHeight: '24px', wordWrap: 'break-word'}}>Copyright © Squad3 2024 – All rights reserved.</div>
      </div>
      <div className="overlay"></div>
    </div>
  )
}
