import './styles/main.scss';
import Home from './pages/Homepage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ThirdwebProvider } from "thirdweb/react";

function App() {
  return (
    <ThirdwebProvider>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </div>
    </ThirdwebProvider>
  );
}

export default App;
