import React from 'react'

export default function SecondSection() {
  return (
    <div id='project-background' className="second-section">
      <img className='accent first-orbit' alt='accent' src="images/orbit-accent.svg" />
      <img className='accent second-orbit' alt='accent' src="images/orbit-accent.svg" />
      <img className='accent second-coin' alt='accent' src="images/coin-accent-2.png" />
      <div className="second-section-container" style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 83, display: 'inline-flex'}}>
        <h1 style={{alignSelf: 'stretch', textAlign: 'center'}}><span style={{color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Industry </span><span style={{color: '#2CE2ED', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Pain Points</span></h1>
        <div className="second-first-container" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
          <div className="second-card" style={{padding: 20, backdropFilter: 'blur(10px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
            <h3 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Difficulty in Acquiring Users</h3>
            <ul>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>High technical barriers: Web2 users face challenges entering the Web3 space due to its complexity.</li>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>Lack of promotional channels: Many projects struggle to reach a wider audience, limiting user acquisition.</li>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>Low engagement: Weak social interaction leads to a decline in user participation after initial tasks.</li>
            </ul>
          </div>
          <div className="second-card" style={{padding: 20, backdropFilter: 'blur(10px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
            <h3 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Ineffective Airdrop Campaigns</h3>
            <ul>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>Lack of user awareness: Limited information results in low participation.
              </li>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>Poor targeting: Airdrops often miss precise targeting, wasting resources.
              </li>
              <li style={{color: 'white', fontSize: 10, fontFamily: 'Inter', fontWeight: '500'}}>Short-term incentives: One-time rewards lead to quick disengagement post-airdrop.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="second-section-container solution-container" style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 83, display: 'inline-flex', position: 'relative'}}>
        <img className='accent third-coin' alt='accent' src="images/coin-accent-1.png" />
        <img className='accent second-dot' alt='accent' src="images/dot-accent-2.png" />
        <img className='accent eight-coin' alt='accent' src="images/coin-accent-3.svg" />
        <img className='accent forth-orbit' alt='accent' src="images/orbit-accent-3.svg" />
        <div className='first-row'>
          <h1 style={{alignSelf: 'stretch', textAlign: 'center', paddingBottom: 24}}><span style={{color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Squad3 </span><span style={{color: '#2CE2ED', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Solutions</span></h1>
          <p style={{color: 'white', fontSize: 14, fontFamily: 'Inter', lineHeight: "26px", fontWeight: '500'}}>Squad3 is a social-to-earn platform that connects Web2 and Web3 communities, enabling users to earn cryptocurrency through task-driven participation. Our goal is to simplify access to Web3 with a fair, transparent reward system.</p>
        </div>
        <div className="second-first-container second-row" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-1.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Social Network</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Enhances social interaction and community belonging through squad guidance by KOLs and leaders.</p>
            </div>
          </div>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-2.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Task Rewards</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Earn crypto rewards for completing tasks, boosting community engagement.</p>
            </div>
          </div>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Web3 Transition</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Lowers barriers for Web2 users, easing the shift into Web3.</p>
            </div>
          </div>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-4.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Project visibility</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Increases project visibility and engagement.</p>
            </div>
          </div>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-5.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Transparent Blockchain</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Uses zero-knowledge proof (ZKP) for transparency and trust.</p>
            </div>
          </div>
          <div className="second-card second-section" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-6.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Multi-Chain Support</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Supports multiple blockchains, promoting ecosystem interaction and sustainability.</p>
            </div>
          </div>
        </div>
      </div>
      <img className='accent first-wire' alt='accent' src="images/wire-accent.svg" />
      <div className="overlay"></div>
      <div className='stripe-container running-block-container'>
        <div className="first-stripe running-block" style={{padding: 10, transform: 'rotate(2.93deg)', transformOrigin: '0 0', background: 'linear-gradient(90deg, #FFF066 0%, #FFD81B 100%)', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 22, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
        </div>
      </div>
    </div>
  )
}
