import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { client } from "../client.js";
import { ConnectButton } from "thirdweb/react";
import { createWallet } from 'thirdweb/wallets';

export default function Header() {
  let location = useLocation();
  const [mobileNav, setMobileNav] = useState(false);
  const wallets = [
    createWallet("io.metamask"),
    createWallet("com.okex.wallet"),
    createWallet("com.bitget.web3"),
    createWallet("com.binance"),
    createWallet("com.bybit")
  ]

  useEffect(() => {
    if(navigator.userAgent.match(/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i)) {
      console.log(process.env.REACT_APP_THIRDWEB_CLIENT_ID);
    }
  }, [mobileNav]);

  useEffect(() => {
    console.log(process.env.REACT_APP_THIRDWEB_CLIENT_ID);
    if (location.hash === "#applications") {
      const element = document.getElementById("applications");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#squad3-ecosystem") {
      const element = document.getElementById("squad3-ecosystem");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#tokenomics") {
      const element = document.getElementById("tokenomics");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#squad3-ecosystem-mobile") {
      const element = document.getElementById("squad3-ecosystem-mobile");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#top-section") {
      const element = document.getElementById("top-section");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#our-goal") {
      const element = document.getElementById("our-goal");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else if (location.hash === "#project-background") {
      const element = document.getElementById("project-background");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location.hash]);

  return (
    <section style={{width: 'calc(100% - 80px)', height: 'min-content', padding: '10px 40px', justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex', position: "absolute", top: '0px', left: '0px'}}>
      <div className='nav-container'>
        <div className='squad3-logo' style={{width: 170, height: 36, position: 'relative'}}>
            <img style={{width: '100%'}} src="images/Logo-final-squad3.svg" alt="Squad Logo" srcSet="" />
        </div>
        <div className='nav-bar desktop' style={{width: 800, alignSelf: 'flex-start', paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20, background: 'rgba(0, 0, 0, 0.30)', borderRadius: 39, border: '1px rgba(207.33, 207.33, 207.33, 1) solid', backdropFilter: 'blur(11.90px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
          <div style={{alignSelf: 'stretch', justifyContent: 'space-evenly', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
            <Link to={"/#top-section"}>
              <div className='nav-button' style={{width: "max-content", height: 19, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Home</h3>
              </div>
            </Link>
            <Link to={"/#applications"}>
              <div className='nav-button' style={{width: "max-content", height: 19, paddingLeft: 4, paddingRight: 4, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Applications</h3>
              </div>
            </Link>
            <Link to={"/#squad3-ecosystem"}>
              <div className='nav-button' style={{width: "max-content", height: 19, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Squad3 Ecosystem</h3>
              </div>
            </Link>
            <Link to={"/#tokenomics"}>
              <div className='nav-button' style={{width: "max-content", height: 19, paddingLeft: 1, paddingRight: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Tokenomics</h3>
              </div>
            </Link>
            <Link to={"/#our-goal"}>
              <div className='nav-button' style={{width: "max-content", height: 19, paddingLeft: 1, paddingRight: 1, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Our Goal</h3>
              </div>
            </Link>
            <Link to={"/#project-background"}>
              <div className='nav-button' style={{width: "max-content", height: 19, justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <h3 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', wordWrap: 'break-word'}}>Project Background</h3>
              </div>
            </Link>
          </div>
        </div>
        <div className='nav-bar mobile' style={{width: 800, alignSelf: 'flex-start', paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20, background: 'rgba(0, 0, 0, 0.30)', borderRadius: 39, border: '1px rgba(207.33, 207.33, 207.33, 1) solid', backdropFilter: 'blur(11.90px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
          <div style={{width: 130, height: 40, position: 'relative'}}>
            <img style={{width: '100%'}} src="images/Logo-final-squad3.svg" alt="Squad Logo" srcSet="" />
          </div>
          <div onClick={() => {
            setMobileNav(true)
          }} className='mobile-menu' style={{width: '100%', height: '100%', position: 'relative'}}>
            <img style={{width: 32, height: 32}} src="images/mobile-menu.svg" alt="Mobile Menu" srcSet=""/>
          </div>
        </div>
        <ConnectButton client={client} connectButton={{
          className: "connect-button",
          style: {width: 150, paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, background: '#2CE2ED', borderRadius: 48, border: '1px white solid', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex'},
          label: "Connect Wallet",
        }} connectModal={{ size: "wide" }} wallets={wallets}/>
      </div>
      {mobileNav ?
      <>
        <div className='mobile-nav' style={{width: '100%', height: '100%', paddingLeft: 20, paddingRight: 20, paddingTop: 16, paddingBottom: 16, background: 'rgba(0, 0, 0, 0.30)', borderRadius: 39, border: '1px rgba(207.33, 207.33, 207.33, 0.40) solid', backdropFilter: 'blur(11.90px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 32, display: 'inline-flex'}}>
          <div style={{width: 120, justifyContent: 'center', alignItems: 'flex-end', gap: 5.30, display: 'inline-flex'}}>
            <img style={{width: '100%', padding: '5px'}} src="images/Logo-final-squad3.svg" alt="Squad Logo" srcSet="" />
          </div>
          <div style={{alignSelf: 'stretch', height: 234, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'flex'}}>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#top-section"}>
              <div style={{width: 45, height: 19, paddingBottom: 2, paddingLeft: 2, paddingRight: 3, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Home</div>
              </div>
            </Link>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#applications"}>
              <div style={{width: 101, height: 19, paddingBottom: 2, paddingLeft: 8, paddingRight: 8, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Applications</div>
              </div>
            </Link>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#squad3-ecosystem-mobile"}>
              <div style={{width: 143, height: 19, paddingBottom: 2, paddingLeft: 6, paddingRight: 6, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Squad3 Ecosystem</div>
              </div>
            </Link>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#tokenomics"}>
              <div style={{width: 93, height: 19, paddingBottom: 2, paddingLeft: 5, paddingRight: 6, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Tokenomics</div>
              </div>
            </Link>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#our-goal"}>
              <div style={{width: 69, height: 19, paddingBottom: 2, paddingLeft: 4, paddingRight: 5, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Our Goal</div>
              </div>
            </Link>
            <Link onClick={() => {
                setMobileNav(false)
              }} to={"/#project-background"}>
              <div style={{width: 146, height: 19, paddingBottom: 2, paddingLeft: 7, paddingRight: 6, justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
                  <div style={{textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Project Background</div>
              </div>
            </Link>
          </div>
          <ConnectButton client={client} connectButton={{
            className: "cta-button",
            style: {width: 150, paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10, background: '#2CE2ED', borderRadius: 48, border: '1px white solid', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'flex'},
            label: "Connect Wallet"
          }} connectModal={{ size: "wide" }} wallets={wallets}/>
        </div>
        <div onClick={() => {
          setMobileNav(false)
        }} className='popup-overlay'></div>
      </> : <></>
      }
    </section>
  )
}
