import React, { useState } from 'react'

export default function HeroSection() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPopup, setIsPopup] = useState(false);
  const [error, setError] = useState(false);

  const handleLoadedData = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setError(true);
  };
  return (
    <>
      <header id='top-section'>
        {isLoading && (
          <div style={{
            position: 'absolute', 
            top: '0', 
            width: '100%'
          }}>
            {error ? (
              <>
                <img style={{width: '100%'}} src="images/video-placeholder.png" alt="placeholder-video" sizes="1600" srcSet="" />
                <div className="overlay"></div>
              </>
              ) :
              (
                <>
                  <img style={{width: '100%'}} src="images/video-placeholder.png" alt="placeholder-video" sizes="1600" srcSet="" />
                  <div className="overlay"></div>
                </>
              )}
          </div>
        )}
        <video
          style={{position: "absolute", top: '0px', left: '0px'}}
          loop
          autoPlay
          muted
          playsInline
          onLoadedData={handleLoadedData}
          onError={handleError}
          width="1600"
        >
          <source src="videos/Squad3-compress.mp4" type="video/mp4"></source>
        </video>
        <div className="main-text">
          <div className='text-container'>
            <div className="left-section">
              <h1 style={{color: '#2CE2ED', fontSize: 72, fontWeight: '700', wordWrap: 'break-word'}}>Redefining <br/>Social to Earn</h1>
              <h2 style={{textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '400', textTransform: 'uppercase', letterSpacing: 3.68, wordWrap: 'break-word'}}>$SQ3 up and earn more with Squad3 </h2>
              <div>
                <div onClick={() => {
                  setIsPopup(true);
                }} className='cta-button' style={{width: '100%', height: '100%', paddingLeft: 20, paddingRight: 20, background: '#2CE2ED', boxShadow: '0px 4px 6.300000190734863px rgba(0, 0, 0, 0.25)', borderRadius: 48, border: '1px white solid', justifyContent: 'center', alignItems: 'center', gap: 10, display: 'inline-flex'}}>
                  <h4 style={{color: 'black', fontSize: 16, fontFamily: 'Inter', fontWeight: '600', wordWrap: 'break-word'}}>Explore Squad3</h4>
                </div>
              </div>
            </div>
            <div className="right-section">
              <h3 style={{width: '100%', textAlign: 'center', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '700', textTransform: 'uppercase', letterSpacing: 3.68, wordWrap: 'break-word'}}>We have connected & reached</h3>
              <div className="card-container" style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 20}}>
                <div className="card-main" style={{flex: '1 1 0', alignSelf: 'stretch', padding: 20, backdropFilter: 'blur(18.60px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <h4 style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '400', letterSpacing: 1.80, wordWrap: 'break-word'}}>KOLs</h4>
                  <h2 style={{alignSelf: 'stretch', textAlign: 'center', color: '#9747FF', fontSize: 32, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word'}}>1,000+</h2>
                </div>
                <div className="card-main" style={{flex: '1 1 0', alignSelf: 'stretch', padding: 20, backdropFilter: 'blur(18.60px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <h4 style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '400', letterSpacing: 1.80, wordWrap: 'break-word'}}>EVENTS</h4>
                  <h2 style={{alignSelf: 'stretch', textAlign: 'center', color: '#9747FF', fontSize: 32, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word'}}>10,000+</h2>
                </div>
              </div>
              <div className="card-container" style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 20}}>
                <div className="card-main" style={{flex: '1 1 0', alignSelf: 'stretch', padding: 20, backdropFilter: 'blur(18.60px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <h4 style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '400', letterSpacing: 1.80, wordWrap: 'break-word'}}>COMMUNITY MEMBERS</h4>
                  <h2 style={{alignSelf: 'stretch', textAlign: 'center', color: '#9747FF', fontSize: 32, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word'}}>100,000+</h2>
                </div>
                <div className="card-main" style={{flex: '1 1 0', alignSelf: 'stretch', padding: 20, backdropFilter: 'blur(18.60px)', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <h4 style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '400', letterSpacing: 1.80, wordWrap: 'break-word'}}>ENGAGEMENT</h4>
                  <h2 style={{alignSelf: 'stretch', textAlign: 'center', color: '#9747FF', fontSize: 32, fontWeight: '700', textTransform: 'uppercase', wordWrap: 'break-word'}}>1M</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="planet-section">
          <img src="images/planet-size.png" alt="Planet" srcSet="" />
        </div>
        <div className="overlay"></div>
        <img className='accent first-coin' style={{width: '165px', height: '195px', transform: 'rotate(-16.95deg)', transformOrigin: '0 0'}} alt='accent' src="images/coin-accent-1.png" />
        <img className='accent first-ball' style={{width: '120px', height: '120px', transformOrigin: '0 0'}} alt='accent' src="images/ball-1.svg" />
        <img className='accent first-dot' style={{height: '147px', transformOrigin: '0 0'}} alt='accent' src="images/dot-accent-1.png" />
      </header>
      <div className='container-divider'>
        <div className='stripe-container running-block-container'>
          <div className="first-stripe running-block" style={{padding: 10, transform: 'rotate(-2.93deg)', transformOrigin: '0 0', background: 'linear-gradient(90deg, #FFF066 0%, #FFD81B 100%)', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
            <div className='content'>
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
            </div>
            <div className='content'>
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
            </div>
            <div className='content'>
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
            </div>
            <div className='content'>
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
              <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
              <img src="images/star.svg" alt="Star Icon" srcSet="" />
            </div>
          </div>
        </div>
      </div>
      {isPopup ?
      <div className='popup-container'>
        <div onClick={() => {
          setIsPopup(false);
        }} className='overlay-popup'></div>
        <div className='popup-content'>
          <h1>Coming Soon</h1>
          <div className='text-follow'>
            <h2>FOLLOW Our </h2>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7719 2.08661H18.5832L12.4415 9.10619L19.6667 18.6582H14.0094L9.5784 12.865L4.50833 18.6582H1.6954L8.26457 11.15L1.33337 2.08661H7.1343L11.1395 7.38189L15.7719 2.08661ZM14.7853 16.9756H16.343L6.28787 3.68089H4.61626L14.7853 16.9756Z" fill="white"/>
            </svg>
            <h2>for last update</h2>
          </div>
          <h3>@BuildWithSquad3</h3>
        </div>
      </div> : <></>
      }
    </>
  )
}
