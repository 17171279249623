import React from 'react'

export default function ThirdSection() {
  return (
    <div className="third-section">
      <img className='accent forth-coin' alt='accent' src="images/coin-accent-1.png" />
      <img className='accent third-dot' alt='accent' src="images/dot-accent-2.png" />
      <img className='accent tenth-coin' alt='accent' src="images/coin-accent-3.svg" />
      <img className='accent nineth-coin' alt='accent' src="images/coin-accent-4.svg" />
      <div id='applications' className="third-section-container first-section" style={{width: '100%', height: '100%', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
        <div className='application-first-section' style={{width: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
          <h1 style={{alignSelf: 'stretch', color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Applications</h1>
          <p style={{alignSelf: 'stretch', lineHeight: '32px', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Squad3 helps break down business goals into tasks, track progress, and integrate features, reducing the need for complex operational systems while ensuring fairness.</p>
        </div>
        <div className='application-second-section' style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 20, display: 'grid'}}>
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-2-1.svg" alt="Icon-1" srcSet="" />
              </div>
              <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                  <h2 style={{color: '#FFCE41', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Digital Marketing</h2>
                  <p style={{textAlign: 'left',color: 'white', fontSize: 12, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Enhances social interaction and community belonging through squad guidance by KOLs and leaders.</p>
              </div>
          </div>
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                  <img src="images/icon-2-2.svg" alt="Icon-1" srcSet="" />
              </div>
              <div className="third-card" style={{alignSelf: 'stretch', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                  <h2 style={{color: '#FFCE41', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>GameFi Tasks</h2>
                  <p style={{textAlign: 'left',color: 'white', fontSize: 12, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Earn crypto rewards for completing tasks, boosting community engagement.</p>
              </div>
          </div>
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-2-3.svg" alt="Icon-1" srcSet="" />
              </div>
              <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                  <h2 style={{color: '#FFCE41', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Educational Motivation</h2>
                  <p style={{textAlign: 'left',color: 'white', fontSize: 12, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Lowers barriers for Web2 users, easing the shift into Web3.</p>
              </div>
          </div>
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-2-4.svg" alt="Icon-1" srcSet="" />
              </div>
              <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
                  <h2 style={{color: '#FFCE41', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Community User Acquisition</h2>
                  <p style={{textAlign: 'left',color: 'white', fontSize: 12, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Increases project visibility and engagement.</p>
              </div>
          </div>
        </div>
      </div>
      <div id='squad3-ecosystem' className="squad3-ecosystem-desktop-container third-section-container second-section" style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 220}}>
        <img className='accent fifth-coin' alt='accent' src="images/coin-accent-2.png" />
        <div className="text-container" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 288, display: 'inline-flex'}}>
          <div style={{width: 476, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
            <div style={{width: 450, justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-1.svg" alt="Icon-3-1" srcSet="" />
              </div>
              <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontSize: 20, fontFamily: 'MADE Outer Sans', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Community Resources</h2>
                <p style={{alignSelf: 'stretch', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '500', lineHeight: "28px", wordWrap: 'break-word'}}>Squad3 unites top-tier Web3 resources, with over 100 Web3 projects, plus 50+ DAO communities joining the platform within 2024.</p>
              </div>
            </div>
            <div style={{width: 476, height: 19}}>
              <img src="images/vector-accent-1.svg" alt="vector-accent-1" srcSet="" />
            </div>
          </div>
          <div style={{width: 476, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 12, display: 'inline-flex'}}>
            <div style={{width: 450, justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-2.svg" alt="Icon-3-2" srcSet="" />
              </div>
              <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                  <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontSize: 20, fontFamily: 'MADE Outer Sans', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Cross-chain Ecological Support</h2>
                  <p style={{alignSelf: 'stretch', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '500', lineHeight: "28px", wordWrap: 'break-word'}}>Squad3 seamlessly integrates with any Layer1, Layer2, or Rollup blockchain network, ensuring comprehensive web3 compatibility.</p>
              </div>
            </div>
            <div style={{width: 476, height: 19}}>
              <img src="images/vector-accent-2.svg" alt="vector-accent-2" srcSet="" />
            </div>
          </div>
        </div>
        <div className="accents-container">
          <div style={{width: '690px', height: '690px', opacity: 0.70, borderRadius: 9999, border: '1px #9747FF solid'}} />
        </div>
        <div className="accents-container">
          <div style={{width: '610px', height: '610px', opacity: 0.70, borderRadius: 9999, border: '1px #9747FF solid'}} />
        </div>
        <div className="accents-container">
          <div style={{width: '530px', height: '530px', opacity: 0.70, borderRadius: 9999, border: '1px #9747FF solid'}} />
        </div>
        <div className="accents-container">
          <div className="circle"/>
        </div>
        <div className="accents-container center">
          <div style={{width: '470px', height: '370px', borderRadius: '100%', background: '#7192FF'}}>
            <h1 style={{textAlign: 'center', color: 'white', fontSize: 48, fontWeight: '700', wordWrap: 'break-word'}}>Squad3<br/>Ecosystem</h1>
            <p style={{textAlign: 'center', color: 'white', fontSize: 18, fontFamily: 'Inter', fontWeight: '500', lineHeight: "26px", wordWrap: 'break-word'}}>A basic bridge that meets<br/>the needs of all parties</p>
          </div>
        </div>
        <div className="text-container" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 288, display: 'inline-flex'}}>
            <div style={{width: 476, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 12, display: 'inline-flex'}}>
              <div style={{width: 476, height: 19, transform: 'rotate(180deg)'}}>
                <img src="images/vector-accent-2.svg" alt="vector-accent-2" srcSet="" />
              </div>
              <div style={{width: 452, justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                    <img src="images/icon-3-3.svg" alt="Icon-3-3" srcSet="" />
                  </div>
                  <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                    <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontSize: 20, fontFamily: 'MADE Outer Sans', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Web2 Community Migration</h2>
                    <p style={{alignSelf: 'stretch', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '500', lineHeight: "28px", wordWrap: 'break-word'}}>Squad3 enables Web2 communities to transition effortlessly into the Web3 ecosystem, offering a smooth migration process.</p>
                  </div>
              </div>
            </div>
            <div style={{width: 476, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-end', gap: 12, display: 'inline-flex'}}>
              <div style={{width: 476, height: 19, transform: 'rotate(180deg)'}}>
                <img src="images/vector-accent-1.svg" alt="vector-accent-1" srcSet="" />
              </div>
              <div style={{width: 450, justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'inline-flex'}}>
                  <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                    <img src="images/icon-3-4.svg" alt="Icon-3-4" srcSet="" />
                  </div>
                  <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', display: 'inline-flex'}}>
                    <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontSize: 20, fontFamily: 'MADE Outer Sans', fontWeight: '500', lineHeight: "40px", wordWrap: 'break-word'}}>Efficient Go-To-Market Strategic</h2>
                    <p style={{alignSelf: 'stretch', color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '500', lineHeight: "28px", wordWrap: 'break-word'}}>By integrating with Squad3, projects can tackle Web3’s user acquisition challenges, significantly boosting engagement and promotion efforts.</p>
                  </div>
              </div>
            </div>
        </div>
      </div>
      <div id='squad3-ecosystem-mobile' className='squad3-ecosystem-container'>
        <div className='stripe-mobile-container'>
          <div className='stripe-container running-block-container mobile'>
            <div className="first-stripe running-block" style={{padding: 10, transform: 'rotate(-2.93deg)', transformOrigin: '0 0', background: 'linear-gradient(90deg, #FFF066 0%, #FFD81B 100%)', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
              <div className='content'>
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
              </div>
              <div className='content'>
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
              </div>
            </div>
          </div>
          <div className='stripe-container running-block-container mobile reverse'>
            <div className="first-stripe running-block" style={{padding: 10, transform: 'rotate(-2.93deg)', transformOrigin: '0 0', background: 'linear-gradient(90deg, #FFF066 0%, #FFD81B 100%)', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
              <div className='content'>
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
              </div>
              <div className='content'>
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
                <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
                <img src="images/star.svg" alt="Star Icon" srcSet="" />
              </div>
            </div>
          </div>
        </div>
        <div className='squad3-ecosystem-mobile' style={{width: '100%', height: '100%', paddingTop: 40, paddingBottom: 20, paddingLeft: 20, paddingRight: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
          <div className='container' style={{width: 353, height: 172, background: '#7192FF', borderRadius: 9999}} />
          <div className='text-container' style={{alignSelf: 'stretch', height: 95, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 12, display: 'flex'}}>
            <h1 style={{color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Squad3 Ecosystem</h1>
            <p style={{alignSelf: 'stretch', textAlign: 'center', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', lineHeight: '26px', wordWrap: 'break-word'}}>A basic bridge that meets the needs <br></br> of all parties</p>
          </div>
        </div>
        <div className="accents-container">
          <div className="circle"/>
        </div>
        <img src="images/line-accent-2.png" alt="line-accent" srcSet="" />
        <div className='squad3-ecosystem-mobile-content'>
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
            <div className='header'>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-1.svg" alt="Icon-1" srcSet="" />
              </div>
              <h2 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Community Resources</h2>
            </div>
            <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
              <p style={{textAlign: 'left',color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Squad3 unites top-tier Web3 resources, with over 100 Web3 projects, plus 50+ DAO communities joining the platform within 2024.</p>
            </div>
          </div>
          <img src="images/line-accent.svg" alt="line-accent" srcSet="" />
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
            <div className='header'>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-2.svg" alt="Icon-1" srcSet="" />
              </div>
              <h2 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Cross-chain Ecological Support</h2>
            </div>
            <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
              <p style={{textAlign: 'left',color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Squad3 seamlessly integrates with any Layer1, Layer2, or Rollup blockchain network, ensuring comprehensive web3 compatibility.</p>
            </div>
          </div>
          <img src="images/line-accent.svg" alt="line-accent" srcSet="" />
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
            <div className='header'>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-3.svg" alt="Icon-1" srcSet="" />
              </div>
              <h2 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Web2 Community Migration</h2>
            </div>
            <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
              <p style={{textAlign: 'left',color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Squad3 enables Web2 communities to transition effortlessly into the Web3 ecosystem, offering a smooth migration process.</p>
            </div>
          </div>
          <img src="images/line-accent.svg" alt="line-accent" srcSet="" />
          <div className='card-border' style={{alignSelf: 'stretch', padding: 20, background: 'rgba(0, 0, 0, 0.20)', borderRadius: 20, border: '1px #9747FF solid', backdropFilter: 'blur(10px)', justifyContent: 'flex-start', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
            <div className='header'>
              <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
                <img src="images/icon-3-4.svg" alt="Icon-1" srcSet="" />
              </div>
              <h2 style={{color: '#FF8CC3', fontSize: 14, fontWeight: '500', wordWrap: 'break-word'}}>Efficient Go-To-Market Strategic</h2>
            </div>
            <div className="third-card" style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'inline-flex'}}>
              <p style={{textAlign: 'left',color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>By integrating with Squad3, projects can tackle Web3’s user acquisition challenges, significantly boosting engagement and promotion efforts.</p>
            </div>
          </div>
        </div>
      </div>
      <div id='tokenomics' className="third-section-container third-section" style={{width: '100%', height: '100%', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', gap: 83, display: 'inline-flex'}}>
        <img className='accent third-orbit mobile-hide' alt='accent' src="images/orbit-accent-2.svg" />
        <img className='accent second-ball mobile-hide' alt='accent' src="images/ball-2.svg" />
        <img className='accent sixth-coin mobile-hide' alt='accent' src="images/coin-accent-1.png" />
        <img className='accent seventh-coin mobile-hide' alt='accent' src="images/coin-accent-2.png" />
        <img className='accent forth-ball' alt='accent' src="images/ball-4.svg" />
        <img className='accent fifth-dot' alt='accent' src="images/dot-accent-1.png" />
        <div className='header-section' style={{width: 1090, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 24, display: 'inline-flex'}}>
          <h1 style={{color: 'white', fontSize: 24, fontWeight: '700', wordWrap: 'break-word'}}>Tokenomics</h1>
          <p style={{lineHeight: '32px', color: 'white', fontSize: 14, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>The Squad protocol establishes a decentralized social economy for event resources. The innovative token economy of Squad3 ensures that technology companies, project communities, developers, and task teams can all receive the required rewards for task performance bonds.</p>
        </div>
        <div className="second-first-container second-row" style={{alignSelf: 'stretch', justifyContent: 'flex-start', alignItems: 'center', gap: 20, display: 'inline-flex'}}>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-1.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Task Margin</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Providers pay $SQ3 as a margin to post tasks. The smart contract locks this fund to ensure the reward is distributed after the task is completed.</p>
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-2.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Task Ranking and Exposure</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Tasks with a higher $SQ3 margin will receive a higher reward rate and better exposure, attracting more squads to participate.</p>
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-3.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Squad Rewards & Captain Commissions</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>After the task is completed, the smart contract allocates $SQ3 rewards based on the squad's contribution. The captain can receive an additional share of the team reward as a commission.</p>
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-4.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Ecosystem Support</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>It is used to support cooperative projects by providing liquidity, reducing startup costs, and conducting airdrop promotions, helping projects to quickly launch and gain market support.</p>
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-5.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Incentivize Developer Ecosystem</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Developers can earn $SQ3 rewards by integrating new features, promoting platform technological innovation, while also providing developer tools and API support to foster ecosystem development.</p>
            </div>
          </div>
          <div className="second-card" style={{padding: 20, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 24, display: 'inline-flex'}}>
            <div className='image-container' style={{width: 40, height: 40, position: 'relative'}}>
              <img src="images/icon-3-3-6.svg" alt="Icon-1" srcSet="" />
            </div>
            <div style={{alignSelf: 'stretch', height: 162, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', gap: 10, display: 'flex'}}>
              <h2 style={{alignSelf: 'stretch', color: '#FF8CC3', fontWeight: '500', wordWrap: 'break-word'}}>Ecosystem Governance</h2>
              <p style={{alignSelf: 'stretch', color: 'white', fontSize: 20, fontFamily: 'Inter', fontWeight: '500', wordWrap: 'break-word'}}>Users holding $SQ3 can participate in platform governance, making key decisions for the platform through voting, enhancing the platform's decentralization and transparency.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay"></div>
      <div className='stripe-container running-block-container'>
        <div className="first-stripe running-block" style={{padding: 10, transform: 'rotate(-2.93deg)', transformOrigin: '0 0', background: 'linear-gradient(90deg, #FFF066 0%, #FFD81B 100%)', justifyContent: 'center', alignItems: 'center', display: 'inline-flex'}}>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
          <div className='content'>
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
            <h4 style={{textAlign: 'left', color: 'black', fontSize: 18, fontWeight: '500', wordWrap: 'break-word'}}>Earn & Contribute to Your Favorite Web3 Projects</h4>
            <img src="images/star.svg" alt="Star Icon" srcSet="" />
          </div>
        </div>
      </div>
    </div>
  )
}
